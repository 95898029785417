<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>参数设置</el-breadcrumb-item>

          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">

        <el-divider content-position="left">积分设置</el-divider>

        <el-form-item label="每消费1元获得">
          <el-input-number v-model="form.moneytointegral" ></el-input-number>
          <span>积分</span>
        </el-form-item>

   

     
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>

        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import { getdatilsetupRequest, setdatilsetupRequest } from "@/api/home";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
import { constants } from "zlib";
export default {
  name: "schooledit",

  components: {
    "image-upload": ImageUpload,
    tinymce: Tinymce
  },

  data () {
    return {
      form: {
        moneytointegral: 0,
     
      },
      selectclassify: [],
      value: ""
    };
  },

  methods: {
    back () {
      this.$router.back();
    },

    getNewContent (data) {
      this.form.content = data;
    },
    imageUpload (url) {
      this.form.logo = url;
    },

    save () {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));

        setdatilsetupRequest(params).then(res => {
          if (res.code == 1) {
            this.$message(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    getdatilsetup () {
      getdatilsetupRequest({
        Id: this.form.id
      }).then(res => {
        this.form = res.data;
      });
    }
  },

  created () {
    this.getdatilsetup();
  },

  validations: {
    form: {}
  }
};
</script> 

<style lang="less" scoped>
</style>